import React, { useEffect, useState } from 'react';

const GitHubStarButton = ({ repoUrl, repoName }) => {
  const [starCount, setStarCount] = useState(null);

  useEffect(() => {
    const fetchStarCount = async () => {
      try {
        const response = await fetch(`https://api.github.com/repos/${repoName}`);
        const data = await response.json();
        setStarCount(data.stargazers_count || 0);
      } catch (error) {
        console.error('Error fetching GitHub stars:', error);
        setStarCount('N/A');
      }
    };

    fetchStarCount();
  }, [repoName]);

  return (
    <a
      href={repoUrl}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-1 px-2 py-1 border border-gray-300 rounded-md text-xs font-medium text-gray-700 hover:bg-gray-100 transition"
    >
      {/* GitHub Icon */}
      <svg
        height="14"
        width="14"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="w-3 h-3 text-gray-700"
      >
        <path
          fillRule="evenodd"
          d="M8 .198a8 8 0 00-2.53 15.6c.4.075.547-.172.547-.385 0-.19-.007-.69-.01-1.354-2.225.482-2.695-1.073-2.695-1.073-.365-.926-.892-1.172-.892-1.172-.73-.5.056-.49.056-.49.807.056 1.23.829 1.23.829.717 1.224 1.88.87 2.338.666.073-.52.28-.87.507-1.07-1.775-.2-3.644-.89-3.644-3.96 0-.875.313-1.59.826-2.15-.083-.202-.36-1.012.078-2.11 0 0 .67-.214 2.2.82a7.68 7.68 0 012.004-.27c.68.003 1.36.092 2.004.27 1.53-1.034 2.2-.82 2.2-.82.438 1.098.16 1.908.08 2.11.513.56.826 1.275.826 2.15 0 3.08-1.872 3.76-3.65 3.96.29.25.54.72.54 1.45 0 1.048-.01 1.896-.01 2.16 0 .214.145.462.55.385A8 8 0 008 .198z"
        />
      </svg>

      {/* Button Text */}
      <span className="text-gray-700">Star</span>

      {/* Star Count */}
      <span className="text-gray-500">⭐ {starCount !== null ? starCount : '...'}</span>
    </a>
  );
};

export default GitHubStarButton;
